@charset "UTF-8";

// sass-lint:disable brace-style
// ----------------------------------------------
.bg-gray {
  background-color: #ccc !important;
}

.bg-light-salmon {
  background-color: $light-salmon !important;
}

.bg-red {
  background-color: #f00 !important;
}

.bg-light-blue {
  background-color: $light-blue !important;
}

.bg-blue {
  background-color: #0051c3 !important;
}

.bg-dodger-blue {
  background-color: #7ec3ec !important;
}

.bg-green-yellow {
  background-color: #a6d171 !important;
}

.bg-soft-red {
  background-color: #f49980 !important;
}

.bg-orange {
  background-color: #f4bd50 !important;
}

.bg-white {
  background-color: #fff !important;
}

// sass-lint:enable brace-style

// ----------------------------------------------
.clear {
  clear: both !important;
}

// ----------------------------------------------
.v-top {
  vertical-align: top !important;
}

.v-middle {
  vertical-align: middle !important;
}

.v-bottom {
  vertical-align: bottom !important;
}
