@charset "UTF-8";

// ----------------------------------------------
.section-fv {
  margin-bottom: 113px;

  @include media-breakpoint-down(md) {
    margin-bottom: 33px;
  }

  .container-lg {
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .section-fv-container {
    position: relative;

    .fv-title-container {
      position: absolute;
      top: 46px;
      right: 45px;
      z-index: 1;
      writing-mode: tb-rl;

      &.fv-title-container2 {
        top: inherit;
        right: inherit;
        bottom: 59px;
        left: 38px;

        @include media-breakpoint-down(md) {
          top: 15px;
          right: inherit;
          bottom: inherit;
          left: 26px;
        }
      }

      @include media-breakpoint-down(md) {
        top: 16px;
        right: 30px;
      }

      .fv-title {
        line-height: 1.3;

        @include media-breakpoint-down(md) {
          line-height: 1.2;
        }

        span {
          background-color: #fff;
          display: inline-block;
          font-size: 26px;
          margin-left: 8px;
          padding-top: 7px;
          letter-spacing: .52rem;

          @include media-breakpoint-down(md) {
            font-size: 16px;
            margin-left: 3px;
            letter-spacing: .36rem;
            padding-top: 4px;
          }
        }
      }
    }

    .fv-logo {
      position: absolute;
      bottom: -41px;
      left: 50%;
      transform: translateX(-50%);
      width: 597px;
      z-index: 2;

      @include media-breakpoint-down(md) {
        position: static;
        transform: none;
        width: 254px;
        margin: 26px auto 0;
      }
    }
  }
}

// ----------------------------------------------
.section-travel {
  margin-bottom: 81px;

  @include media-breakpoint-down(md) {
    margin-bottom: 62px;
  }

  .list-travel {
    display: flex;
    padding-top: 39px;
    padding-bottom: 40px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding-top: 15px;
      padding-bottom: 25px;
    }

    li {
      width: 100%;
      max-width: 300px;

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }

      &:not(:last-child) {
        margin-right: 50px;

        @include media-breakpoint-down(md) {
          margin-right: 0;
          margin-bottom: 27px;
        }
      }
    }

    .img-thumbnail {
      display: block;
      margin-bottom: 15px;
      overflow: hidden;

      img {
        width: 100%;
        transition: all 150ms linear;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    .info {
      display: flex;
      font-size: 13px;
      margin-bottom: 11px;

      .time {
        width: 84px;
        letter-spacing: .08rem;
      }

      .category {
        width: calc(100% - 84px);

        a {
          display: inline-block;
          color: #000;
          text-decoration: none;
          border: 1px solid $gray;
          padding: 0 7px;
          letter-spacing: .08rem;
          line-height: 1.3;
          transition: all 150ms linear;

          &:hover {
            color: #fff;
            background: $gray;
          }
        }
      }
    }

    .title {
      a {
        display: block;
        font-size: 16px;
        text-decoration: none;
        color: #000;
        font-weight: normal;
        letter-spacing: .08rem;
        line-height: 1.55;
        transition: all 150ms linear;

        @include media-breakpoint-down(md) {
          letter-spacing: 0;
          line-height: 1.4;
        }

        &:hover {
          color: $orange;
        }
      }
    }
  }

  .btn-container {
    text-align: center;
  }
}

// ----------------------------------------------
.section-about {
  margin-bottom: 98px;

  @include media-breakpoint-down(md) {
    margin-bottom: 63px;
  }

  .text-md {
    text-align: center;
    font-size: 17px;
    line-height: 1.87;
    margin-bottom: 25px;

    @include media-breakpoint-down(md) {
      margin-bottom: 18px;
    }
  }

  .about-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 76px;

    @include media-breakpoint-down(md) {
      display: block;
      margin-bottom: 16px;
    }

    .image {
      width: 662px;

      @include media-breakpoint-down(md) {
        width: 77%;
        margin-left: -3.5%;
      }

      @include media-breakpoint-between(md, xl) {
        width: 55.93%;
      }

      img {
        width: 100%;
      }
    }

    .text-box {
      width: 380px;
      margin-left: 57px;
      margin-top: 42px;

      @include media-breakpoint-between(md, xl) {
        width: 39.5%;
        margin-left: 4.5%;
        margin-top: 3.7%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-left: 0;
        margin-top: 18px;
        text-align: center;
      }

      .text {
        font-size: 16px;
        margin-bottom: 66px;
        line-height: 2.1;
        letter-spacing: .03rem;

        @include media-breakpoint-between(md, xl) {
          font-size: 1.4vw;
          margin-bottom: 11.6%;
        }

        @include media-breakpoint-down(md) {
          line-height: 1.88;
          margin-bottom: 0;
          font-size: 17px;
          margin-left: -6px;
          margin-right: -17px;
        }
      }
    }
  }

  .about-image {
    margin-bottom: 41px;
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-left: -3.2%;
      margin-right: -3.5%;
      margin-bottom: 26px;
    }

    img {
      width: 100%;
      max-width: 837px;

      @include media-breakpoint-up(xl) {
        margin-left: 75px;
      }
    }
  }

  .about-container-bot {
    flex-direction: row-reverse;
    justify-content: left;

    @include media-breakpoint-down(md) {
      margin-top: 64px;
      margin-bottom: 0;
    }

    .image {
      width: 44.8%;

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 71px;
      }

      h2 {
        font-size: 32px;

        @include media-breakpoint-down(md) {
          font-size: 28px;
          text-align: center;
          margin-bottom: 21px;
        }
      }

      h3 {
        font-size: 23px;

        @include media-breakpoint-down(md) {
          font-size: 22px;
          text-align: center;
          margin-bottom: 18px;
        }
      }
    }

    .text-box {
      width: 55.17%;
      margin-top: -52px;
      margin-left: 0;
      padding-left: 7.7%;
      padding-right: 2%;

      @include media-breakpoint-down(md) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }

      h2 {
        font-size: 32px;
        text-indent: -6px;
        letter-spacing: .04rem;
        margin-bottom: 39px;

        @include media-breakpoint-between(md, xl) {
          font-size: 2.67vw;
        }
      }

      h3 {
        font-size: 23px;
        letter-spacing: .11rem;
        margin-bottom: 24px;

        @include media-breakpoint-between(md, xl) {
          font-size: 1.92vw;
        }
      }

      .text {
        font-size: 16px;
        margin-bottom: 40px;

        @include media-breakpoint-between(md, xl) {
          font-size: 1.4vw;
        }

        @include media-breakpoint-down(md) {
          line-height: 1.85;
          text-align: justify;
          letter-spacing: 0;
          margin-left: 0;
          margin-bottom: 24px;
          margin-right: 0;
        }
      }
    }
  }
}

// ----------------------------------------------
.section-media {
  padding-bottom: 87px;

  @include media-breakpoint-down(md) {
    padding-bottom: 30px;
    margin-bottom: 25px;
  }

  .list-media {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 785px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 7px;

    @include media-breakpoint-down(md) {
      padding-top: 16px;
      padding-bottom: 0;
      margin-right: -20px;
      margin-left: -20px;
    }

    li {
      margin: 0 29px 47px;

      @include media-breakpoint-down(md) {
        margin: 0 7px 21px;
      }

      &:nth-child(1) {
        @include media-breakpoint-down(md) {
          width: 21.6vw;
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-down(md) {
          width: 15vw;
        }
      }

      &:nth-child(3) {
        @include media-breakpoint-down(md) {
          width: 16vw;
        }
      }

      &:nth-child(4) {
        @include media-breakpoint-down(md) {
          width: 20vw;
        }
      }

      &:nth-child(5) {
        @include media-breakpoint-down(md) {
          width: 18.4vw;
        }
      }

      &:nth-child(6) {
        @include media-breakpoint-down(md) {
          width: 23.8vw;
        }
      }

      &:nth-child(7) {
        @include media-breakpoint-down(md) {
          width: 31.8vw;
        }
      }

      &:nth-child(8) {
        @include media-breakpoint-up(md) {
          margin-left: 0;
        }

        @include media-breakpoint-down(md) {
          width: 18.3vw;
        }
      }

      &:nth-child(9) {
        @include media-breakpoint-down(md) {
          width: 19vw;
        }
      }

      &:nth-child(10) {
        margin-right: 0;

        @include media-breakpoint-down(md) {
          width: 18.7vw;
        }
      }

      &:nth-child(11) {
        margin-right: 0;

        @include media-breakpoint-down(md) {
          width: 20.2vw;
        }
      }
    }
  }
}

// ----------------------------------------------
.section-contact {
  margin-bottom: 62px;

  @include media-breakpoint-down(md) {
    margin-bottom: 100px;
  }

  .lead {
    margin-bottom: 32px;

    @include media-breakpoint-down(md) {
      margin-bottom: 25px;
      letter-spacing: -.03rem;
    }
  }

  .btn-container {
    text-align: center;
    margin-bottom: 39px;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    .btn-section-contact {
      width: 297px;
      font-size: 21px;
      padding: 10px 39px 11px 19px;

      @include media-breakpoint-down(md) {
        background: #ec6c00 url("../img/common/arrow-white.svg") no-repeat right 9px center/32px auto;
        padding: 7px 39px 9px 19px;
        width: 100%;
        text-align: center;
      }
    }
  }

  .contact-info {
    text-align: center;
    background: #f5f5f5;
    border-radius: 25px;
    max-width: 668px;
    margin: auto;
    padding-top: 22px;
    padding-bottom: 25px;

    @include media-breakpoint-down(md) {
      padding: 15px 30px 13px;
      margin-right: -30px;
      margin-left: -30px;
      border-radius: 0;
      max-width: calc(100% + 60px);
    }

    .info-text {
      font-size: 16px;

      @include media-breakpoint-down(md) {
        font-size: 15px;
      }
    }

    .tel {
      margin-bottom: 5px;

      @include media-breakpoint-down(md) {
        margin-bottom: 2px;
      }

      a {
        font-size: 46px;
        line-height: 1.3;
        color: #000;
        text-decoration: none;
        letter-spacing: .11rem;

        @include media-breakpoint-up(md) {
          pointer-events: none;
        }

        @include media-breakpoint-down(md) {
          font-size: 45px;
          line-height: 1.1;
          letter-spacing: .15rem;
        }
      }
    }

    .time-work {
      font-size: 16px;
      margin-bottom: 7px;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        margin-bottom: 7px;
      }
    }

    .note {
      font-size: 12px;
      text-align: center;

      @include media-breakpoint-down(md) {
        text-align: left;
      }
    }
  }
}

// ----------------------------------------------
.section-qa {
  margin-bottom: 68px;

  @include media-breakpoint-down(md) {
    margin-bottom: 104px;
  }

  .btn-container {
    text-align: center;
  }

  .lead {
    margin-bottom: 34px;

    @include media-breakpoint-down(md) {
      margin-bottom: 23px;
    }
  }
}

// ----------------------------------------------
.section-about-page {
  padding-top: 109px;
  margin-bottom: 80px;

  @include media-breakpoint-down(md) {
    padding-top: 50px;
    margin-bottom: 45px;
  }

  .lead {
    margin-bottom: 44px;

    @include media-breakpoint-down(md) {
      margin-bottom: 14px;
    }
  }

  .about-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  .image {
    width: 529px;
    margin-right: 30px;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 21px;
      margin-right: 0;
    }
  }

  .intro {
    width: 413px;
    margin-bottom: -9px;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-left: 0;
      margin-bottom: 0;
    }

    .text {
      font-size: 16px;
      line-height: 2.1;

      @include media-breakpoint-down(md) {
        line-height: 1.82;
        letter-spacing: -.03rem;
      }
    }
  }
}

// ----------------------------------------------
.section-doctor {
  background-color: #f4fffb;
  padding-top: 70px;
  padding-bottom: 84px;

  @include media-breakpoint-down(md) {
    padding-top: 39px;
    padding-bottom: 43px;
  }

  .container-content {
    padding-top: 37px;
    padding-left: 2px;

    @include media-breakpoint-down(md) {
      padding-top: 0;
      padding-left: 0;
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }

    &.align-items-end {
      align-items: flex-end;
    }

    &:nth-child(2n) {
      flex-direction: row-reverse;

      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
      }

      .image {
        margin-left: 20px;
        margin-right: 0;

        @include media-breakpoint-down(md) {
          margin-left: 0;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 70px;

      @include media-breakpoint-down(md) {
        margin-bottom: 21px;
      }
    }

    &:nth-child(3) {
      margin-top: -21px;

      @include media-breakpoint-down(md) {
        margin-top: 23px;
      }

      .image {
        img {
          width: 461px;

          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
      }
    }
  }

  .image {
    width: 554px;
    text-align: center;
    margin-right: 20px;

    @include media-breakpoint-between(md, lg) {
      width: 350px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-right: 0;
    }

    .text {
      @include media-breakpoint-down(md) {
        text-align: justify;
        margin-top: 18px;
        line-height: 1.8;
      }
    }
  }

  .content-body {
    width: 413px;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .point {
      display: inline-block;
      color: $gray;
      border-bottom: 1px solid $gray;
      line-height: 1;
      margin-bottom: 35px;
      padding-right: 2px;
      padding-left: 2px;

      @include media-breakpoint-down(md) {
        display: block;
        text-align: center;
        border-bottom: 0;
        padding-top: 6px;
        margin-bottom: 4px;
      }

      small {
        font-size: 20px;

        @include media-breakpoint-down(md) {
          font-size: 16px;
          border-bottom: 1px solid $gray;
        }
      }

      .number {
        font-size: 36px;
        color: #5db6e7;
        margin-left: 8px;

        @include media-breakpoint-down(md) {
          display: block;
          font-size: 31px;
          margin-left: 0;
          margin-top: 6px;
        }
      }
    }

    .title {
      font-size: 23px;
      color: #5db6e7;
      margin-bottom: 16px;

      @include media-breakpoint-down(md) {
        margin-right: -18px;
        margin-left: -18px;
        margin-bottom: 22px;
        text-align: center;
        line-height: 1.55;
      }
    }

    .text {
      font-size: 16px;
      line-height: 2.1;
      letter-spacing: .06rem;
      margin-bottom: 4px;
    }
  }
}

// ----------------------------------------------
.section-area {
  padding-top: 90px;
  padding-bottom: 31px;

  @include media-breakpoint-down(md) {
    padding-top: 54px;
    padding-bottom: 45px;
  }

  .area-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-top: 7px;
    }
  }

  .map {
    width: 336px;
    margin-left: 11px;

    @include media-breakpoint-down(md) {
      width: 50.333vw;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 18px;
      transform: translateX(-15px);
    }
  }

  .info {
    width: 305px;
    transform: translate(-21px, 17px);

    @include media-breakpoint-down(md) {
      width: 100%;
      text-align: center;
      transform: none;
    }
  }

  .text {
    font-size: 16px;
    line-height: 2.1;

    @include media-breakpoint-down(md) {
      display: inline-block;
      text-align: left;
      line-height: 1.8;
      margin-left: -11px;
    }
  }
}

// ----------------------------------------------
.section-flow {
  background: #f4fffb;
  padding-top: 74px;
  padding-bottom: 68px;

  @include media-breakpoint-down(md) {
    padding-top: 45px;
    padding-bottom: 2px;
  }

  .list-flow {
    display: flex;
    flex-wrap: wrap;
    padding-top: 35px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding-top: 2px;
    }

    li {
      width: calc(100% / 3 - 33px);
      margin-right: 49px;
      margin-bottom: 38px;

      @include media-breakpoint-between(md, lg) {
        width: calc(100% / 3 - 14px);
        margin-right: 21px;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 28px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(1) {
        .box-flow {
          .image {
            transform: translate(2px, 1px);

            @include media-breakpoint-down(md) {
              transform: translate(5px, 7px);
            }
          }
        }
      }

      &:nth-child(2) {
        .box-flow {
          .image {
            transform: translate(11px, -16px);

            @include media-breakpoint-down(md) {
              transform: translate(7px, -12px);
            }
          }
        }
      }

      &:nth-child(3) {
        .box-flow {
          .image {
            transform: translate(0, -4px);

            @include media-breakpoint-down(md) {
              transform: translate(-4px, -4px);
            }
          }
        }
      }

      &:nth-child(4) {
        .box-flow {
          .image {
            transform: translate(-6px, -19px);

            @include media-breakpoint-down(md) {
              transform: translate(-14px, -10px);
            }
          }
        }
      }

      &:nth-child(5) {
        .box-flow {
          .image {
            transform: translate(-13px, -10px);

            @include media-breakpoint-down(md) {
              transform: translate(-17px, -9px);
            }
          }
        }
      }

      &:nth-child(6) {
        .box-flow {
          .image {
            transform: translate(1px, -13px);

            @include media-breakpoint-down(md) {
              transform: translate(-4px, -9px);
            }
          }
        }
      }
    }

    .number {
      font-size: 36px;
      color: $blue;
      text-align: center;
      margin-bottom: -4px;

      @include media-breakpoint-down(md) {
        font-size: 31px;
      }
    }

    .box-flow {
      background-color: #d7eef7;
      border-radius: 25px;
      padding-bottom: 17px;

      @include media-breakpoint-down(md) {
        min-height: 402.6362px;
      }

      .title {
        font-size: 23px;
        text-align: center;
        border-bottom: 2px solid #f4fffb;
        padding-top: 9px;
        padding-bottom: 10px;
        margin-bottom: 25px;
      }

      .image {
        text-align: center;
        height: 132px;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          margin-bottom: 29px;
        }
      }

      .text {
        position: relative;
        font-size: 16px;
        line-height: 1.65;
        padding-right: 21px;
        padding-left: 21px;
        letter-spacing: -.05rem;

        @include media-breakpoint-down(md) {
          line-height: 1.85;
        }
      }
    }
  }
}

// ----------------------------------------------
.section-trip {
  padding-top: 87px;
  padding-bottom: 98px;

  @include media-breakpoint-down(md) {
    padding-top: 48px;
    padding-bottom: 42px;
  }

  .lead {
    @include media-breakpoint-down(md) {
      letter-spacing: -.02rem;
    }
  }

  .trip-container {
    padding-top: 77px;
    padding-bottom: 74px;

    @include media-breakpoint-down(md) {
      padding-top: 25px;
      padding-bottom: 35px;
    }
  }

  .item {
    &:not(:last-child) {
      margin-bottom: 89px;

      @include media-breakpoint-down(md) {
        margin-bottom: 54px;
      }
    }
  }

  .item-body {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-bottom: 31px;
    }

    .item-info {
      width: 44.6%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      .case {
        font-weight: normal;
        border-bottom: 1px solid $gray;
        line-height: 1.1;
        margin-top: -6px;
        margin-bottom: 18px;

        @include media-breakpoint-down(md) {
          text-align: center;
          border-bottom: 0;
          margin-bottom: 4px;
        }

        small {
          font-size: 20px;
          color: $gray;

          @include media-breakpoint-down(md) {
            font-size: 16px;
            border-bottom: 1px solid #000;
          }
        }

        .number {
          font-size: 36px;
          color: $blue;
          margin-left: 10px;

          @include media-breakpoint-down(md) {
            display: block;
            font-size: 31px;
            margin-top: 4px;
            margin-left: 0;
          }
        }
      }

      .title {
        font-size: 23px;
        color: $blue;
        font-weight: normal;
        margin-bottom: 9px;

        @include media-breakpoint-down(md) {
          font-size: 22px;
          text-align: center;
          margin-bottom: 16px;
        }
      }

      .text {
        font-size: 16px;
        line-height: 1.65;

        @include media-breakpoint-down(md) {
          line-height: 1.8;
        }

        &:not(:last-child) {
          margin-bottom: 31px;

          @include media-breakpoint-down(md) {
            margin-bottom: 30px;
          }
        }

        .red {
          color: #ff0000;
        }
      }
    }

    .item-image {
      width: 52.8%;

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 19px;
      }

      img {
        border-radius: 50px;

        @include media-breakpoint-down(md) {
          border-radius: 15px;
        }
      }
    }
  }

  .item-footer {
    display: flex;
    align-items: center;
    background: #d7eef7;
    border-radius: 25px;
    padding: 17px 26px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 21px 20px 25px;
    }

    .title {
      width: 257px;
      font-size: 23px;
      border-right: 1px solid $gray;
      padding-top: 17px;
      padding-right: 20px;
      padding-bottom: 24px;
      letter-spacing: .04rem;
      white-space: nowrap;

      @include media-breakpoint-down(md) {
        width: 100%;
        border-right: 0;
        text-align: center;
        padding: 0;
        margin-bottom: 12px;
        white-space: normal;
      }
    }

    .text {
      width: calc(100% - 257px);
      font-size: 16px;
      line-height: 1.6;
      padding-left: 26px;
      margin-top: -1px;

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-top: 0;
        padding-left: 0;
      }
    }
  }

  .btn-container {
    text-align: center;
  }
}

// ----------------------------------------------
.section-transportation-accommodation {
  background: #f4fffb;
  padding-top: 68px;
  padding-bottom: 16px;

  @include media-breakpoint-down(md) {
    padding-top: 40px;
    padding-bottom: 0;
  }

  .lead-blue {
    font-size: 23px;
    color: $blue;
    margin-top: 26px;
    margin-bottom: 5px;

    @include media-breakpoint-down(md) {
      text-align: center;
      line-height: 1.6;
      margin-top: 18px;
      margin-bottom: 9px;
    }
  }

  .text {
    font-size: 16px;
    text-align: center;
    line-height: 2.1;
    margin-bottom: 52px;

    @include media-breakpoint-down(md) {
      text-align: justify;
      line-height: 1.8;
      margin-bottom: 20px;
    }
  }

  .list-image {
    display: flex;
    flex-wrap: wrap;
    margin-left: 2px;
    margin-bottom: 57px;

    @include media-breakpoint-down(md) {
      margin-left: 0;
      margin-bottom: 23px;
    }

    li {
      width: 48.75%;
      margin-right: 2.4%;
      margin-bottom: 24px;

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 9px;
      }

      img {
        border-radius: 50px;

        @include media-breakpoint-down(md) {
          border-radius: 15px;
        }
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &.sm {
        width: 31.05%;

        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }

      &.lg {
        width: 66.54%;

        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }
  }

  .btn-container {
    text-align: center;
  }

  .box-container {
    padding: 75px 0;

    @include media-breakpoint-down(md) {
      padding: 53px 0 30px;
    }

    .item {
      &:not(:last-child) {
        margin-bottom: 75px;

        @include media-breakpoint-down(md) {
          margin-bottom: 56px;
        }
      }
    }

    .title {
      font-size: 23px;
      color: $blue;
      text-align: center;
      margin-bottom: 12px;

      @include media-breakpoint-down(md) {
        margin-bottom: 4px;
      }
    }

    .text {
      min-height: 51px;
      margin-bottom: 24px;

      @include media-breakpoint-down(md) {
        min-height: 62px;
        margin-bottom: 21px;
      }
    }

    .list-thumbnail {
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }

      li {
        width: 31.7%;

        @include media-breakpoint-down(md) {
          width: 100%;
        }

        &:not(:last-child) {
          @include media-breakpoint-down(md) {
            margin-bottom: 9px;
          }
        }

        img {
          border-radius: 50px;

          @include media-breakpoint-down(md) {
            width: 100%;
            border-radius: 15px;
          }
        }
      }
    }
  }
}

// ----------------------------------------------
.section-fees {
  padding-top: 89px;
  padding-bottom: 75px;

  @include media-breakpoint-down(md) {
    padding-top: 49px;
    padding-bottom: 39px;
  }

  .list-fees {
    display: flex;
    justify-content: space-between;
    max-width: 733px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 50px;
    padding-bottom: 72px;

    @include media-breakpoint-between(md, xl) {
      max-width: 633px;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
      flex-direction: column;
      padding-top: 13px;
      padding-bottom: 43px;
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 184px;
      height: 184px;
      border-radius: 50%;
      font-size: 23px;
      line-height: 1;
      background: #d7eef7;
      padding-bottom: 9px;

      @include media-breakpoint-between(md, xl) {
        width: 150px;
        height: 150px;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        height: 100%;
        padding-top: 6px;
        padding-bottom: 8px;
        border-radius: 0;
        font-size: 16px;
      }

      &:not(:last-child) {
        @include media-breakpoint-down(md) {
          margin-bottom: 40px;
        }

        &:after {
          content: "";
          position: absolute;
          width: 33px;
          height: 33px;
          background: url("../img/icon/plus.svg") no-repeat center center/100%;
          top: 50%;
          right: -62px;
          transform: translateY(-50%);

          @include media-breakpoint-down(md) {
            width: 20px;
            height: 20px;
            top: auto;
            right: auto;
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  .box-fees {
    max-width: 733px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
      margin-bottom: 27px;
    }

    .item {
      &:not(:last-child) {
        margin-bottom: 74px;

        @include media-breakpoint-down(md) {
          margin-bottom: 34px;
        }
      }
    }

    .title {
      font-size: 23px;
      text-align: center;
      margin-bottom: 35px;

      @include media-breakpoint-down(md) {
        margin-bottom: 13px;
      }
    }

    .title-table {
      font-size: 23px;
      text-align: center;
      padding-right: 120px;
      padding-left: 120px;
      margin-bottom: 8px;
      // margin-bottom: 23px;
      letter-spacing: .1rem;

      &.custom-margin {
        margin-bottom: 23px;

        @include media-breakpoint-down(md) {
          margin-bottom: 2px;
        }
      }

      @include media-breakpoint-down(md) {
        font-size: 20px;
        margin-bottom: 2px;
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    .box-table {
      position: relative;
      margin-bottom: 4px;

      @include media-breakpoint-down(md) {
        margin-bottom: 6px;
      }
    }

    .time {
      position: absolute;
      top: 13px;
      right: 0;
      font-size: 13px;
      letter-spacing: .07rem;

      &.custom-top {
        top: 23px;
      }

      @include media-breakpoint-down(md) {
        position: static;
        font-size: 12px;
        text-align: center;
        margin-bottom: 11px;
      }
    }

    table {
      width: 100%;
      font-size: 18px;
      border: 0;

      @include media-breakpoint-down(md) {
        font-size: 15px;
      }

      thead {
        background: #d7eef7;
      }

      th,
      td {
        width: 50%;
        padding: 9px 19px 10px;
        border-color: #3c3c3c;
        line-height: 1.55;

        &.custom-height {
          height: 77px;

          @include media-breakpoint-down(md) {
            height: 47.8px;
          }
        }

        @include media-breakpoint-down(md) {
          // padding: 5px 9px 9px;
          padding: 0 7px;
          height: 47.8px;
          line-height: 1.3;
        }
      }

      th {
        text-align: center;
      }

      td {
        &:nth-child(2) {
          text-align: center;
        }

        &:nth-child(3) {
          text-align: center;
        }

        &.text-begin {
          @include media-breakpoint-down(md) {
            text-align: left;
          }
        }
      }
    }

    .box-price {
      table {
        th,
        td {
          width: 33.33%;

          small {
            font-size: 12px;
            letter-spacing: -.06rem;

            @include media-breakpoint-down(md) {
              display: block;
              font-size: 10px;
            }
          }
        }

        td {
          &:first-child {
            padding-right: 10px;

            @include media-breakpoint-down(md) {
              padding-right: 5px;
            }
          }
        }
      }
    }

    .box-price-2 {
      table {
        th,
        td {
          @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100% !important;
            text-align: center;
          }
        }

        td {
          @include media-breakpoint-down(md) {
            height: 78px;
            line-height: 1.5;
            border-top: 0;
          }
        }
      }
    }

    .text-note {
      font-size: 13px;
      text-indent: -13px;
      padding-left: 13px;

      &.custom-note {
        padding-left: 0;
        text-indent: 0;
        margin-top: 9px;
        margin-left: -5px;
        margin-bottom: 19px;
        line-height: 1.46;

        @include media-breakpoint-down(md) {
          margin-top: 8px;
          margin-bottom: 16px;
          line-height: 1.23;
        }
      }

      @include media-breakpoint-down(md) {
        line-height: 1.25;
        padding-left: 0;
        text-indent: 0;
      }
    }
  }

  .btn-container {
    text-align: center;
  }
}

// ----------------------------------------------
.section-blog {
  padding: 126px 0 131px;

  @include media-breakpoint-down(md) {
    padding: 50px 0;
  }

  .blog-page-inner {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  .blog-page-left {
    width: 720px;
    margin-bottom: 0;
    margin-right: 30px;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 120px;
    }
  }

  .blog-page-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
    }

    .blog-item {
      width: 45.9%;

      @include media-breakpoint-between(md, lg) {
        width: 100%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 330px;
      }
    }

    .blog-article-link {
      margin-bottom: 50px;
    }

    .blog-article-date {
      margin-right: 3px;

      @include media-breakpoint-down(md) {
        letter-spacing: .05em;
      }
    }
  }

  .blog-article {
    position: relative;
    z-index: 1;
  }

  .blog-article-link {
    position: relative;
    display: block;
    border: 1px solid #1a1a1a;
    width: 100%;
    padding: 10px 10px 34px;
    margin-bottom: 75px;
    text-decoration: none;

    &:hover {
      .blog-article-image {
        transform: scale(1.1);
      }
    }

    @include media-breakpoint-down(md) {
      padding: 8px 10px 22px;
      margin: 0 auto 30px;
      width: 100%;
    }
  }

  .blog-article-wrapper {
    height: 170px;
    margin-bottom: 31px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      height: 132px;
      margin-bottom: 22px;
    }
  }

  .blog-article-image {
    width: 100%;
    height: 170px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform .3s ease-in-out;
    backface-visibility: hidden;
  }

  .blog-article-details {
    padding: 0 9px;
    margin-bottom: 21px;

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin-bottom: 15px;
    }
  }

  .blog-article-date {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    color: #101010;
    letter-spacing: .1em;
    margin-right: 4px;
  }

  .blog-article-category {
    font-size: 12px;
    color: #101010;
    border: 1px solid #101010;
    text-transform: uppercase;
    padding: 3.5px 19px 2.5px;
    margin-top: -3px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: normal;
    background-color: #fff;
    letter-spacing: .1em;

    @include media-breakpoint-down(md) {
      margin-top: 0;
      padding: 0 14px;
      letter-spacing: .04em;
    }
  }

  .blog-article-title {
    font-size: 15px;
    color: #101010;
    padding: 0 9px;
    letter-spacing: .11em;
    line-height: 1.8;
    max-height: 54px;
    font-weight: 700;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }
}

// ----------------------------------------------
.section-single {
  padding: 105px 0 118px;

  @include media-breakpoint-down(md) {
    padding: 50px 0;
  }

  .single-inner {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  .single-left {
    width: 725px;
    margin-right: 30px;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-right: 0;
    }
  }

  .single-details {
    margin-bottom: 19px;

    @include media-breakpoint-down(md) {
      margin-bottom: 33px;
    }
  }

  .single-details-date {
    font-size: 16px;
    color: #101010;
    font-weight: 700;
    letter-spacing: .09em;
    font-family: Roboto;
    margin-right: 2px;

    @include media-breakpoint-down(md) {
      font-size: 12px;
      margin-right: 13px;
    }
  }

  .single-details-category {
    font-family: Roboto;
    font-size: 14px;
    color: #101010;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .15em;
    margin-right: 16px;
    background-color: #fff;
    text-align: center;
    border: 1px solid #101010;
    padding: 0 14px 0 18px;
    line-height: 2.3;
    height: 31px;
    display: inline-block;
    margin-top: -2px;
    text-decoration: none;

    @include media-breakpoint-down(md) {
      font-size: 11px;
      margin-right: 0;
      padding: 0 10px 0 14px;
      height: 24px;
    }
  }

  .single-title {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: .1em;
    margin-bottom: 30px;
    margin-left: -3px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 35px;
      margin-left: 0;
      letter-spacing: .05rem;
    }
  }

  .single-featured {
    padding-top: 55.5%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 25px;
    max-width: 721px;

    @include media-breakpoint-down(md) {
      margin-bottom: 34px;
    }
  }

  .single-body,
  .single-body p {
    font-size: 16px;
    color: #101010;
    letter-spacing: .22em;
    line-height: 2;
    font-weight: 400;
  }

  .single-body {
    word-break: break-word;

    p {
      margin-bottom: 44px;

      @include media-breakpoint-down(md) {
        font-size: 15px;
        letter-spacing: .28em;
        line-height: 2.1;
        margin-bottom: 39px;
      }
    }

    h2,
    h3 {
      color: #101010;
      letter-spacing: .1em;
      position: relative;
    }

    h2 {
      line-height: 1.4;
      margin-bottom: 28px;
      padding-left: 32px;
      font-size: 30px;
      font-weight: bold;

      @include media-breakpoint-down(md) {
        font-size: 20px;
        letter-spacing: .15em;
        line-height: 1.39;
        padding-bottom: 9px;
        margin-bottom: 20px;
        padding-left: 24px;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 16px;
        height: 16px;
        background-color: rgba(255, 150, 0, 40%);

        @include media-breakpoint-down(md) {
          width: 10px;
          height: 10px;
          left: 0;
          top: 5px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        left: 8px;
        top: 17px;
        width: 16px;
        height: 16px;
        background-color: #ff9600;

        @include media-breakpoint-down(md) {
          width: 10px;
          height: 10px;
          left: 6px;
          top: 10px;
        }
      }
    }

    h3 {
      font-size: 26px;
      padding-left: 27px;
      margin-bottom: 29px;

      @include media-breakpoint-down(md) {
        font-size: 16px;
        padding-left: 20px;
        margin-bottom: 29px;
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 16px;
        width: 16px;
        height: 16px;
        background-color: #ffe9c9;

        @include media-breakpoint-down(md) {
          width: 10px;
          height: 10px;
          top: 11px;
        }
      }
    }

    h4 {
      color: #101010;
      font-size: 22px;
      letter-spacing: .1em;
      margin-bottom: 31px;

      @include media-breakpoint-down(md) {
        letter-spacing: .15em;
        font-size: 16px;
        margin-bottom: 29px;
      }
    }

    ol {
      list-style: none;
      margin: 0 0 56px 32px;
      counter-reset: section;

      @include media-breakpoint-down(md) {
        margin: 0 0 41px;
      }

      li {
        position: relative;
        padding-left: 20px;
        letter-spacing: .22em;
        margin-bottom: 21px;
        font-size: 13px;
        font-weight: 400;

        @include media-breakpoint-down(md) {
          padding-left: 17px;
          letter-spacing: .29em;
          line-height: 2.6;
          margin-bottom: 2px;
          font-size: 12px;
        }

        &:before {
          font-size: 16px;
          color: #fe9601;
          counter-increment: section;
          content: counters(section, ".") " ";
          position: absolute;
          left: 0;
          top: -4px;

          @include media-breakpoint-down(md) {
            font-size: 12px;
            top: 0;
          }
        }
      }
    }

    ul {
      margin: 0 0 38px 30px;

      @include media-breakpoint-down(md) {
        margin: -8px 0 20px;
      }

      li {
        position: relative;
        padding-left: 22px;
        letter-spacing: .22em;
        margin-bottom: 21px;
        font-size: 13px;
        font-weight: 400;

        @include media-breakpoint-down(md) {
          padding-left: 16px;
          letter-spacing: .28em;
          margin-bottom: 2px;
          line-height: 2.6;
          font-size: 12px;
        }

        &:before {
          content: "";
          width: 10px;
          height: 10px;
          background-color: #fe9601;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 5px;

          @include media-breakpoint-down(md) {
            width: 10px;
            height: 10px;
            top: 11px;
          }
        }
      }
    }

    blockquote {
      border: 1px solid #101010;
      background-color: #fff;
      padding: 47px 79px 2px;
      position: relative;
      max-width: 721px;
      margin: 0 auto 60px;

      @include media-breakpoint-down(md) {
        padding: 55px 23px 6px 20px;
        border: 1px solid #101010;
        margin-bottom: 51px;
      }

      &:before {
        content: "\e977";
        font-family: icomoon;
        position: absolute;
        top: 31px;
        left: 30px;
        font-size: 20px;
        color: #fe9601;

        @include media-breakpoint-down(md) {
          top: 26px;
          left: 14px;
          font-size: 18px;
        }
      }

      &:after {
        content: "\e977";
        font-family: icomoon;
        position: absolute;
        bottom: 30px;
        right: 30px;
        font-size: 20px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        color: #fe9601;

        @include media-breakpoint-down(md) {
          bottom: 20px;
          right: 14px;
          font-size: 18px;
        }
      }

      p {
        letter-spacing: .2em;
        line-height: 1.8;

        & > iframe {
          width: 100% !important;
          margin: 61px auto 37px;
          display: block;
          max-width: 721px;

          @include media-breakpoint-down(md) {
            height: 45vw;
            margin-bottom: 0;
          }
        }

        & > img {
          margin: 0;
          max-width: 721px;

          @include media-breakpoint-down(md) {
            max-height: 100%;
            max-width: 100%;
            margin-bottom: -31px;
          }
        }
      }
    }
  }

  .sns-article-list {
    margin-bottom: 46px;
  }

  .sns-article-item {
    display: inline-block;
    vertical-align: top;
    margin-right: 6px;
    margin-bottom: 6px;
  }

  .sns-article-link {
    font-size: 12px;
    color: #fff;
    display: inline-block;
    min-width: 122px;
    text-decoration: none;

    svg {
      font-size: 12px;
      transform: scale(.4);
      fill: #fff;
      height: 30px;
      transform-origin: center;
      vertical-align: middle;
    }

    &.sns-article-link-facebook {
      background-color: #315095;
      padding: 2px 24px 0 27px;
      letter-spacing: .09em;
      height: 30px;

      svg {
        margin-right: -3px;
        margin-top: -2px;
      }
    }

    &.sns-article-link-twitter {
      padding: 2px 19px 0 16px;
      letter-spacing: .09em;
      background-color: #45aceb;

      svg {
        margin-right: -9px;
        margin-top: -2px;
      }
    }

    &.sns-article-link-hatena {
      padding: 1px 10px 0 4px;
      letter-spacing: .02em;
      background-color: #41a3db;

      svg {
        margin-right: -7px;
        margin-top: -1px;
      }
    }

    &.sns-article-link-line {
      background-color: #5ec33a;
      padding: 0 7px 0 3px;
      letter-spacing: .08em;
      height: 30px;

      svg {
        height: 36px;
        margin-top: -4px;
        margin-right: -9px;
      }
    }
  }

  .btn-container {
    text-align: center;
  }
}

// ----------------------------------------------
.section-vision-page {
  padding-top: 84px;
  padding-bottom: 30px;

  @include media-breakpoint-down(md) {
    padding-top: 45px;
    padding-bottom: 48px;
  }

  .vision-info-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 92px;

    @include media-breakpoint-down(md) {
      margin-bottom: 56px;
    }

    .vision-images {
      img {
        border-radius: 50px;
        overflow: hidden;

        @include media-breakpoint-down(md) {
          border-radius: 15px;
        }
      }
    }
  }

  .vision-info-container2 {
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 94px;

    @include media-breakpoint-up(xl) {
      margin-left: -49px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 76px;
    }

    .vision-images {
      margin-top: 79px;
      margin-left: 0;
      width: 489px;

      @include media-breakpoint-between(md, xl) {
        width: 46.62%;
      }

      @include media-breakpoint-down(md) {
        margin-top: 30px;
        width: 100%;
      }

      .image {
        & + .image {
          margin-top: 33.8%;
          margin-left: 9%;

          @include media-breakpoint-down(md) {
            margin-top: 4.4%;
            margin-left: 0;
          }
        }
      }
    }

    .vision-info {
      padding-left: 73px;
      width: 560px;

      @include media-breakpoint-between(md, xl) {
        padding-left: 2.5%;
        width: 52.7%;
      }

      @include media-breakpoint-down(md) {
        padding-left: 0;
        width: 100%;
      }

      .vision-info-text {
        padding-left: 16px;

        @include media-breakpoint-down(md) {
          padding-left: 0;
        }
      }
    }
  }

  .vision-info-container3 {
    margin-bottom: 121px;
    margin-right: -56px;

    @include media-breakpoint-between(md, xl) {
      margin-right: 0;
    }

    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-bottom: 82px;
    }

    .vision-images {
      margin-top: 79px;
      margin-left: -8px;
      width: 502px;

      @include media-breakpoint-between(md, xl) {
        width: 47.54%;
        margin-left: 1.3%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-left: 0;
        margin-top: 34px;
      }

      .image {
        &:first-child {
          margin-left: 11.2%;

          @include media-breakpoint-down(md) {
            margin-left: 0;
          }
        }

        & + .image {
          margin-top: 25.1%;

          @include media-breakpoint-down(md) {
            margin-top: 4.5%;
          }
        }
      }
    }

    .vision-info {
      width: 550px;
      padding-left: 6px;

      @include media-breakpoint-between(md, xl) {
        width: 51.1%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        padding-left: 0;
      }
    }
  }

  .vision-info {
    width: 547px;
    padding-left: 7px;

    @include media-breakpoint-between(md, xl) {
      width: 51.8%;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-left: 0;
    }

    .vision-info-title {
      line-height: 1.6;
      text-align: left;
      margin-top: 0;
      margin-bottom: 33px;
      letter-spacing: 0;

      @include media-breakpoint-between(md, xl) {
        font-size: 2.9vw;
      }

      @include media-breakpoint-down(md) {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 27px;
      }
    }

    .vision-info-text {
      font-size: 18px;
      line-height: 2.05;

      @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 1.82;
      }

      @include media-breakpoint-between(md, lg) {
        font-size: 1.55vw;
      }
    }
  }

  .vision-images {
    width: 445px;
    margin-left: 8px;
    margin-top: 84px;

    @include media-breakpoint-between(md, xl) {
      width: 44.5%;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-left: 0;
      margin-top: 35px;
    }

    .image-info {
      margin-left: 59px;
      margin-top: 35px;

      @include media-breakpoint-between(md, lg) {
        margin-left: 35px;
      }

      @include media-breakpoint-down(md) {
        margin-left: 0;
        margin-top: 19px;
      }

      .doctor {
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 16px;

        @include media-breakpoint-between(md, lg) {
          font-size: 14px;
        }

        @include media-breakpoint-down(md) {
          margin-bottom: 10px;
        }

        .name {
          font-size: 23px;

          @include media-breakpoint-between(md, lg) {
            font-size: 18px;
          }

          @include media-breakpoint-down(md) {
            font-weight: 700;
          }
        }
      }

      .doctor-info {
        font-size: 15px;
        line-height: 1.92;

        @include media-breakpoint-between(md, lg) {
          font-size: 13px;
        }

        @include media-breakpoint-down(md) {
          line-height: 1.73;
        }
      }
    }
  }

  .vision-widen-circle {
    .title-32 {
      border-bottom: 1px solid #3c3c3c;
      padding-bottom: 8px;
      margin-bottom: 12px;

      @include media-breakpoint-down(md) {
        margin-bottom: 9px;
      }
    }

    .lead {
      margin-bottom: 62px;

      @include media-breakpoint-down(md) {
        text-align: left;
        font-size: 15px;
        line-height: 1.75;
        margin-bottom: 22px;
      }
    }

    .list-project {
      display: flex;
      justify-content: space-between;
      margin-bottom: 65px;

      @include media-breakpoint-down(md) {
        display: block;
        margin-bottom: 28px;
      }

      li {
        width: 47%;
        background-color: #f5f5f5;
        border-radius: 25px;
        text-align: center;
        padding: 30px 33px 26px;

        @include media-breakpoint-between(md, lg) {
          width: 49%;
        }

        @include media-breakpoint-down(md) {
          width: 100%;
          padding: 15px 22px 18px;
        }

        & + li {
          @include media-breakpoint-down(md) {
            margin-top: 20px;
          }
        }

        &:nth-child(2) {
          // margin-bottom: 13px;

          h4 {
            @include media-breakpoint-down(md) {
              margin-bottom: 8px;
            }
          }
        }

        h4 {
          font-size: 23px;
          margin-bottom: 24px;

          @include media-breakpoint-between(md, lg) {
            font-size: 18px;
          }

          @include media-breakpoint-down(md) {
            font-size: 22px;
            margin-bottom: 20px;
          }
        }

        .project-image {
          min-height: 171px;
          margin-bottom: 24px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include media-breakpoint-down(md) {
            margin-bottom: 24px;
            min-height: inherit;
          }

          img {
            width: 144px;

            @include media-breakpoint-down(md) {
              width: 137px;
            }
          }

          &.project-image2 {
            img {
              width: 269px;

              @include media-breakpoint-down(md) {
                width: 240px;
              }
            }

            @include media-breakpoint-down(md) {
              margin-bottom: 4px;
            }
          }
        }

        .text {
          font-size: 16px;
          line-height: 1.65;
          text-align: justify;

          @include media-breakpoint-between(md, lg) {
            font-size: 14px;
          }

          @include media-breakpoint-down(md) {
            line-height: 1.77;
          }
        }
      }
    }
  }

  .btn-container {
    text-align: center;

    .btn-page-vision {
      font-size: 23px;
      width: 307px;
      text-align: left;
      padding: 8px 39px 11px 21px;
      background: #ec6c00 url("../img/common/arrow-white.svg") no-repeat right 19px center/33px auto;

      &:hover {
        background: #fff url("../img/common/arrow-orange.svg") no-repeat right 19px center/33px auto;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        padding: 8px 39px 9px 12px;
        background: #ec6c00 url("../img/common/arrow-white.svg") no-repeat right 9px center/33px auto;
        font-size: 21px;
      }
    }
  }
}

// ----------------------------------------------
.section-contact-page {
  padding-bottom: 45px;

  @include media-breakpoint-down(md) {
    padding-top: 26px;
    padding-bottom: 100px;
  }

  .list-tab-contact {
    position: relative;
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    // border-bottom: 2px solid #f9be00;
    margin-top: -45px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &:after {
      content: "";
      position: absolute;
      background-color: #fbecb9;
      width: 100%;
      height: 81px;
      left: 0;
      bottom: 0;
      z-index: 0;
    }

    li {
      position: relative;
      width: 333.33px;
      z-index: 1;

      &:nth-child(1) {
        z-index: 3;

        a {
          border-radius: 25px 25px 0 0;
        }
      }

      &:nth-child(2) {
        z-index: 2;
      }

      &:last-child {
        a {
          border-radius: 25px 25px 0 0;

          &:after {
            content: "";
            display: none;
          }
        }
      }

      a {
        display: block;
        background-color: #fff;
        text-decoration: none;
        text-align: center;
        font-size: 16px;
        color: #3c3c3c;
        padding: 48px 0 41px;
        position: relative;
        border-bottom: 2px solid #f9be00;
        border-radius: 25px 25px 0 0;

        &:after {
          content: "";
          position: absolute;
          background-color: #f9be00;
          width: 2px;
          height: 81px;
          right: -1px;
          bottom: 0;
        }

        &.rounded {
          border-radius: 25px 25px 25px 0;
          border-right: 2px solid #f9be00;

          &:after {
            content: "";
            display: none;
          }
        }
      }

      &.active {
        a {
          background-color: #fbecb9;
          border-top: 2px solid #f9be00;
          border-left: 2px solid #f9be00;
          border-bottom: 0;
          padding: 46px 0 41px;

          &:after {
            content: "";
            display: none;
          }
        }

        & + li {
          a {
            border-left: 2px solid #f9be00;
            border-radius: 25px 25px 0;
          }
        }

        &[data-tab="#tab2"] {
          a {
            border-left: 0;

            &.rounded-2 {
              &:after {
                display: none;
              }
            }
          }
        }

        &[data-tab="#tab3"] {
          a {
            border-right: 2px solid #f9be00;
            border-left: 0;
          }
        }
      }

      &[data-tab="#tab2"] {
        a {
          &.rounded-2 {
            border-right: 2px solid #f9be00;
            border-radius: 25px 25px 25px 0;

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  .tab-container {
    .tab-content {
      @include media-breakpoint-up(md) {
        display: none;

        &.show {
          display: block;
        }
      }

      @include media-breakpoint-down(md) {
        background-color: #fbecb9;
        // padding: 0 1px 1px;
        border-radius: 15px;
        overflow: hidden;
      }

      &.actived {
        @include media-breakpoint-down(md) {
          border: 1px solid #f9be00;
        }
      }

      &.tab-content1 {
        .btn-container {
          @include media-breakpoint-down(md) {
            margin-bottom: 25px;
          }
        }
      }

      &.tab-content2 {
        .lead {
          margin-bottom: 23px;

          @include media-breakpoint-down(md) {
            margin-bottom: 11px;
          }
        }
      }

      & + .tab-content {
        @include media-breakpoint-down(md) {
          margin-top: 30px;
        }
      }
    }

    .tab-label {
      font-size: 14px;
      text-align: center;
      padding: 19.5px 0;
      position: relative;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        background: url("../img/icon/plus-rounded.svg") no-repeat;
        background-size: 17px 17px;
        width: 17px;
        height: 17px;
        right: 18px;
        top: 22px;
        transition: $hover-transition-speed;
      }

      &.rotate {
        &:after {
          transform: rotate(45deg);
        }
      }
    }

    .tab-content-box {
      @include media-breakpoint-down(md) {
        background: #fff;
        border-radius: 0 0 15px 15px;
        display: none;
        padding: 16px 20px 31px;
      }
    }

    .title-tab-contact {
      @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 1.4;
        padding-bottom: 5px;
        margin-bottom: 8px;
        letter-spacing: 0;
      }
    }

    .lead {
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        font-size: 15px;
        margin-bottom: 16px;
        line-height: 1.74;
      }
    }

    .btn-container {
      text-align: center;
      margin-bottom: 46px;

      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }

      a {
        width: 297px;
        font-size: 23px;
        background: #ec6c00 url("../img/common/arrow-white.svg") no-repeat right 13px center/25px auto;
        padding: 8.5px 40px 11px;
        text-align: center;

        &:hover {
          background: #fff url("../img/common/arrow-orange.svg") no-repeat right 13px center/25px auto;

          @include media-breakpoint-down(md) {
            background: #fff url("../img/common/arrow-orange.svg") no-repeat right 8px center/34px auto;
          }
        }

        @include media-breakpoint-down(md) {
          width: 274px;
          font-size: 21px;
          background: #ec6c00 url("../img/common/arrow-white.svg") no-repeat right 8px center/34px auto;
          padding: 8.5px 25px 9px 12px;
        }
      }
    }

    .telephone-consultation {
      text-align: center;
      background: #f5f5f5;
      border-radius: 25px;
      max-width: 668px;
      margin: auto;
      padding-top: 22px;
      padding-bottom: 25px;

      @include media-breakpoint-down(md) {
        padding: 15px 30px 23px;
        margin-right: -30px;
        margin-left: -30px;
        margin-bottom: -31px;
        border-radius: 0;
      }

      .text {
        font-size: 16px;

        @include media-breakpoint-down(md) {
          font-size: 15px;
        }
      }

      .phone {
        margin-bottom: 5px;
        line-height: 1.3;
        letter-spacing: .13rem;

        @include media-breakpoint-down(md) {
          margin-bottom: 5px;
          line-height: 1.1;
          letter-spacing: .08rem;
        }

        a {
          font-size: 46px;
          color: #3c3c3c;
          text-decoration: none;

          @include media-breakpoint-up(md) {
            pointer-events: none;
          }

          @include media-breakpoint-down(md) {
            font-size: 42px;
          }
        }
      }

      .time-work {
        font-size: 16px;
        margin-bottom: 7px;

        @include media-breakpoint-down(md) {
          font-size: 15px;
          letter-spacing: -.05rem;
          margin-bottom: 10px;
        }
      }

      .note {
        font-size: 12px;
        margin-bottom: 0;
        letter-spacing: .02rem;

        @include media-breakpoint-down(md) {
          text-align: left;
          line-height: 1.3;
        }
      }
    }
  }
}

// ----------------------------------------------
.section-qa-page {
  padding-top: 61px;
  padding-bottom: 41px;

  @include media-breakpoint-down(md) {
    padding-top: 56px;
    padding-bottom: 0;
  }

  .section-qa-content {
    margin-bottom: 54px;

    @include media-breakpoint-down(md) {
      margin-bottom: 45px;
    }

    .qa-content-title {
      margin-bottom: 25px;
      letter-spacing: .15rem;
      padding-bottom: 8px;

      @include media-breakpoint-down(md) {
        letter-spacing: .1rem;
        padding-bottom: 4px;
        margin-bottom: 12px;
      }
    }

    .qa-content {
      margin-bottom: 36px;

      @include media-breakpoint-down(md) {
        margin-bottom: 38px;
      }

      .question {
        line-height: 1.85;
        font-size: 19px;
        margin-bottom: 15px;

        @include media-breakpoint-down(md) {
          font-size: 17px;
          line-height: 1.75;
          margin-bottom: 12px;
          letter-spacing: -.02rem;
        }
      }

      .answer {
        font-size: 16px;
        line-height: 1.85;
        letter-spacing: .019rem;

        @include media-breakpoint-down(md) {
          font-size: 15px;
          line-height: 1.75;
        }

        &.red {
          color: #ff0000;
        }
      }
    }
  }
}
