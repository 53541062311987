@charset "UTF-8";

// ----------------------------------------------
@mixin background-gradient($color1, $color2) {
  background-image: linear-gradient(to bottom, $color1 0%, $color2 100%);
}

// ----------------------------------------------
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(-45deg, #fa6c01 0%, #fcbd0a 11.7378%, #ffffff 25.2752%, #92d1f4 57.1241%, #2ba2ee 81.5877%, #1d41d7 100%);
  background-size: 100% 200%;
  animation: background-linear 10s linear infinite forwards;
  transition: all 1000ms linear;
  z-index: 1000;

  .loader-logo {
    width: 339px;
    animation: image-scale 3s linear forwards;
    transition: opacity 1000ms linear;

    @include media-breakpoint-down(md) {
      width: 312px;
    }
  }

  &.loaded {
    opacity: 0;
    visibility: hidden;

    .loader-logo {
      opacity: 0;
    }
  }
}

// ----------------------------------------------
@keyframes background-linear {
  0% { background-position: 100% 100%; }
  50% { background-position: 100% 0; }
  100% { background-position: 100% 100%; }
}

// ----------------------------------------------
@keyframes image-scale {
  0% {
    transform: scale(.7);
    opacity: .5;
    filter: blur(10px);
  }

  100% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0);
  }
}

// ----------------------------------------------
.loader-child {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  transition: all 350ms linear;
  opacity: 1;
  visibility: visible;
  z-index: 1000;

  &.loaded {
    opacity: 0;
    visibility: hidden;
  }
}

// ----------------------------------------------
.lead {
  font-size: 16px;
  text-align: center;
  line-height: 2.13;

  @include media-breakpoint-down(md) {
    line-height: 1.85;
    text-align: justify;
  }
}

// ----------------------------------------------
.page-header {
  .page-title {
    @include background-gradient(#83cee4, #ede4d4);

    position: relative;
    color: #fff;
    border-radius: 50px;
    padding-top: 105px;
    padding-bottom: 105px;
    min-height: 309px;

    @include media-breakpoint-down(md) {
      border-radius: 15px;
      padding-top: 32px;
      padding-bottom: 32px;
      min-height: 135px;
      text-align: center;
    }
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .title-en {
    font-size: 20px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
  }

  .title-jp {
    font-size: 40px;

    @include media-breakpoint-down(md) {
      font-size: 28px;
    }
  }

  &.page-header-about {
    .page-title {
      @include background-gradient(#4cb8d8, #ffe2b0);
    }

    .page-anchor {
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 1060px;
      padding-right: 30px;
      padding-left: 30px;

      @include media-breakpoint-down(md) {
        position: static;
        transform: none;
        padding-right: 25px;
        padding-left: 25px;
        margin-top: 28px;
        margin-bottom: -2px;
      }

      .list-anchor {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        background: #fff;
        border-radius: 25px;
        padding: 21px 36px;

        @include media-breakpoint-down(md) {
          flex-direction: column;
          background: transparent;
          border-radius: 0;
          padding: 0;
        }

        @include media-breakpoint-between(md, xl) {
          justify-content: center;
          padding-bottom: 11px;
        }

        li {
          @include media-breakpoint-down(md) {
            width: 100%;
          }

          @include media-breakpoint-between(md, xl) {
            margin-right: 10px;
            margin-left: 10px;
            margin-bottom: 10px;
          }

          &:not(:last-child) {
            @include media-breakpoint-down(md) {
              margin-bottom: 15px;
            }
          }

          a {
            font-size: 13px;
            color: #9d9d9d;
            text-decoration: none;
            padding-bottom: 6px;

            @include media-breakpoint-down(md) {
              display: block;
              position: relative;
              width: 100%;
              font-size: 14px;
              color: #000;
              background: #fff;
              padding: 10px 35px 9px;
              border-radius: 15px;
              letter-spacing: -.1rem;

              &:after {
                content: "";
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%) rotate(90deg);
                width: 19px;
                height: 14px;
                background: url("../img/common/arrow-black.svg") no-repeat right center/cover;
              }
            }

            &.active,
            &:hover {
              @include media-breakpoint-up(md) {
                color: $gray;
                border-bottom: 1px solid $gray;
              }
            }
          }
        }
      }
    }
  }

  &.page-header-vision {
    .page-title {
      // @include background-gradient(#6c97dc, #ffbaa0);
      @include background-gradient(#4cb8d8, #ffe2b0);
    }
  }

  &.page-header-contact {
    .page-title {
      @include background-gradient(#fa6c00, #facd3b);
    }
  }

  &.page-header-qa {
    .page-title {
      background-image: linear-gradient(to bottom, #3a7bd5 0%, #00d4db 100%);
    }
  }

  &.page-header-blog {
    .page-title {
      @include background-gradient(#6f91d4, #f4bba1);
    }
  }
}

// ----------------------------------------------
.sidebar {
  width: 330px;

  @include media-breakpoint-between(md, xl) {
    flex-shrink: 0;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
  }

  .sidebar-latest {
    margin-bottom: 69px;
  }

  .sidebar-heading {
    margin-bottom: 31px;
  }

  .sidebar-heading-text {
    color: #101010;
    font-size: 22px;
    text-align: center;
    max-width: 330px;
    margin: 0 auto;
    border: 1px solid #c7c7c7;

    @include background-gradient(#f6f6f6, #d2e6ef);

    height: 58px;
    line-height: 58px;
    letter-spacing: .2em;
    padding-left: 4px;
  }

  .sidebar-post-item {
    padding-bottom: 30px;
    margin-bottom: 29px;
    position: relative;
    border-bottom: 2px dotted #e3e0e0;
  }

  .sidebar-post-link {
    display: flex;
    justify-content: space-between;
    position: relative;
    text-decoration: none;
    z-index: 1;

    &:hover {
      .sidebar-post-image {
        transform: scale(1.1);
      }
    }
  }

  .sidebar-post-wrapper {
    width: 126px;
    height: 70px;
    overflow: hidden;
  }

  .sidebar-post-image {
    width: 126px;
    height: 70px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform .3s ease;
    backface-visibility: hidden;
  }

  .sidebar-post-details {
    width: 177px;
  }

  .sidebar-post-date {
    font-size: 12px;
    color: #101010;
    letter-spacing: .1em;
  }

  .sidebar-post-category {
    font-size: 10px;
    color: #101010;
    text-transform: uppercase;
    border: 1px solid #101010;
    margin-left: 7px;
    padding: 6px 8px 5px;
    display: inline-block;
    margin-top: -1px;
    position: relative;
    vertical-align: middle;
    line-height: 1;
    background-color: #fff;
  }

  .sidebar-post-title {
    font-size: 13px;
    color: #101010;
    display: block;
    margin-top: 8px;
    letter-spacing: .1em;
    line-height: 1.6;
    max-height: 40px;
    font-weight: bold;
    overflow: hidden;
  }

  .sidebar-categories {
    margin-bottom: 64px;
  }

  .sidebar-heading-categories {
    margin-bottom: 30px;
  }

  .sidebar-categories-link {
    display: block;
    font-family: Roboto;
    font-size: 15px;
    color: #101010;
    border-bottom: 2px dotted #e3e0e0;
    text-transform: uppercase;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    letter-spacing: .1em;
    position: relative;
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: $orange;
    }
  }

  .sidebar-categories-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 13px;
    background: url("../img/icon/btn-arrow.svg") no-repeat center center/100%;
  }

  .sidebar-ranking {
    .sidebar-post-article {
      &:before {
        counter-increment: section;
        content: counter(section);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        text-align: center;
        background-color: #5e5e5e;
        color: #fff;
        font-size: 13px;
        z-index: 2;
        top: -11px;
        left: -13px;
        font-family: Roboto;
      }
    }
  }

  &.sidebar-single {
    margin-top: 58px;

    @include media-breakpoint-down(md) {
      margin-top: 80px;
    }
  }
}

// ----------------------------------------------
.text-right {
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}
