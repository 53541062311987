@charset "UTF-8";

// ----------------------------------------------
.pagination {
  text-align: center;
  margin-top: 60px;

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
}

.pagination-next,
.pagination-prev {
  width: 70px;
  height: 48px;
  background-color: #5e5e5e;
  display: inline-block;
  font-size: 13px;
  color: #fff;
  line-height: 3.8;
  margin: 0 2px;
  transition: background-color .3s ease, color .3s ease;
  vertical-align: top;

  @include media-breakpoint-down(md) {
    width: 54px;
    height: 41px;
    line-height: 3.8;
    margin-left: 0;
    font-size: 10px;
  }

  &.is-disable {
    opacity: .5;
    pointer-events: none;
  }
}

.pagination-prev {
  margin-right: 6px;

  @include media-breakpoint-down(md) {
    margin-right: 0;
  }
}

.pagination-next {
  margin-left: 6px;

  @include media-breakpoint-down(md) {
    margin-left: 0;
  }
}

.pagination-next-link,
.pagination-prev-link {
  color: #fff;
  display: block;
  height: 48px;
  text-decoration: none;
}

.pagination-list {
  font-size: 13px;
  height: 48px;
  text-align: center;
  border: 1px solid $gray;
  border-radius: 0;
  width: 219px;
  text-align-last: center;
  vertical-align: top;
  display: inline-block;
  position: relative;
  cursor: pointer;
  z-index: 1;
  transition: background-color .3s ease, color .3s ease;

  @include media-breakpoint-down(md) {
    width: 180px;
    height: 41px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 20px;
    right: 15px;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 10px solid $gray;
    display: block;
    transition: all .3s ease;
    z-index: 3;

    @include media-breakpoint-down(md) {
      top: 18px;
      right: 10px;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-top: 6px solid $gray;
    }
  }

  &:hover {
    .pagination-label {
      background: $gray;
      color: #fff;
    }

    &:before {
      border-top-color: #fff;
    }
  }
}

.pagination-label {
  font-family: Roboto;
  font-size: 13px;
  position: absolute;
  width: 100%;
  text-align: center;
  height: 100%;
  line-height: 3.7;
  display: block;
  z-index: 2;
  pointer-events: none;
  background-color: #fff;
  transition: background-color .3s ease, color .3s ease;

  @include media-breakpoint-down(md) {
    line-height: 3.4;
  }
}

.pagination-list-select {
  height: 100%;
  text-align: center;
  width: 100%;
  text-align-last: center;
}
