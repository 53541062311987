@charset "UTF-8";

// sass-lint:disable brace-style
// ----------------------------------------------
.mt-0 {
  margin-top: 0 !important;
}

// ----------------------------------------------
.me-0 {
  margin-right: 0 !important;
}

// ----------------------------------------------
.mb-0 {
  margin-bottom: 0 !important;
}

// ----------------------------------------------
.ms-0 {
  margin-left: 0 !important;
}

// ----------------------------------------------
.pt-0 {
  padding-top: 0 !important;
}

// ----------------------------------------------
.pe-0 {
  padding-right: 0 !important;
}

// ----------------------------------------------
.pb-0 {
  padding-bottom: 0 !important;
}

// ----------------------------------------------
.ps-0 {
  padding-left: 0 !important;
}

// ----------------------------------------------
.d-none {
  display: none 0 !important;
}

// ----------------------------------------------
.d-md-none {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

// ----------------------------------------------
.d-md-block {
  display: none !important;

  @include media-breakpoint-up(md) {
    display: block !important;
  }
}
// sass-lint:enable brace-style
