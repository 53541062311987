@charset "UTF-8";

// ----------------------------------------------
html {
  // @include media-breakpoint-down(sm) {
  //   font-size: $font-size-base - 1;
  // }

  &.wf-active {
    body {
      visibility: visible;
    }
  }
}

// ----------------------------------------------
body {
  position: relative;// fix for parent <html> tag width is smaller than body tag.
  visibility: hidden; // for html.wf-active {}
  padding-top: 119px;
  font-family: $font-family-serif;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }

  @include media-breakpoint-down(md) {
    padding-top: 83px;
  }
}

.container-lg {
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.container {
  max-width: 1060px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

// sans = without
// sans-serif = without 'serif'
// ----------------------------------------------
.sans-serif {
  font-family: $font-family-sans-serif;
}

.serif {
  font-family: $font-family-serif;
}

.roboto {
  font-family: Roboto, sans-serif;
}
// ----------------------------------------------
// .noto-sans-jp {
//   font-family: "Noto Sans", "Noto Sans JP", "Noto Sans Japanese", sans-serif;
//
//   &.fw-400 {
//     font-weight: 400;
//   }
//
//   &.fw-500 {
//     font-weight: 500;
//   }
//
//   &.fw-700 {
//     font-weight: 700;
//   }
// }

// ----------------------------------------------
a {
  &,
  img {
    @include transition(all, $hover-transition-speed, ease);
  }

  &.underline {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &.hover {
    &:hover {
      @include media-breakpoint-up(sm) {
        opacity: .8;
      }
    }
  }

  &.hover-img {
    &:hover {
      img {
        @include media-breakpoint-up(sm) {
          opacity: .8;
        }
      }
    }
  }

  .img-responsive {
    display: inline-block;
  }
}

// ----------------------------------------------
img {
  &.width-100p {
    width: 100% !important;
  }
}

// ----------------------------------------------
.img-responsive-xs {
  @include media-breakpoint-down(sm) {
    @include img-fluid();
  }
}

.img-responsive-sm {
  @include media-breakpoint-down(sm) {
    @include img-fluid();
  }
}

.img-responsive-md {
  @include media-breakpoint-down(md) {
    @include img-fluid();
  }
}
