@charset "UTF-8";

// sass-lint:disable space-before-colon, brace-style
//
// Gray
// ----------------------------------------------
$lighter-gray: #dcdcdc;
$light-gray: #d3d3d3;
$dark-gray: #3c3c3c;
$gray: #3c3c3c;
$black: #000000;

//
// Pink
// ----------------------------------------------
$pink: #ffc0cb;
$light-pink: #ffb6c1;
$hot-pink: #ff69b4;
$deep-pink: #ff1493;

//
// Red
// ----------------------------------------------
$light-salmon: #ffa07a;
$salmon: #fa8072;
$crimson: #dc143c;
$dark-red: #8b0000;
$red: #ff0000;

//
// Orange
// ----------------------------------------------
$orange: #ec6c00;

//
// Yellow
// ----------------------------------------------
$yellow: #ffff00;
$light-yellow: #ffffe0;
$lemon: #fffacd;

//
// Brown
// ----------------------------------------------
$chocolate: #d2691e;
$brown: #a52a2a;

//
// Green
// ----------------------------------------------
$light-green: #90ee90;
$green: #008000;
$dark-green: #006400;

//
// Cyan
// ----------------------------------------------
$aqua: #00ffff;
$cyan: #00ffff;
$light-cyan: #e0ffff;
$dark-cyan: #008b8b;

//
// Blue
// ----------------------------------------------
$light-blue: #add8e6;
$sky-blue: #87ceeb;
$blue: #5db6e7;
$dark-blue: #00008b;
$navy: #000080;

//
// Purple
// ----------------------------------------------
$magenta: #ff00ff;
$purple: #800080;

// sass-lint:enable space-before-colon, brace-style
