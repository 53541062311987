@charset "UTF-8";

// ----------------------------------------------
.swiper-simple {
  margin-bottom: 88px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 46px;
  }
}

// ----------------------------------------------
.swiper-pagination {
  position: static;
}

// ----------------------------------------------
.swiper-button-next,
.swiper-button-prev {
  @include transition(all, $hover-transition-speed, ease);
  width: 50px;
  height: 50px;
  background: #ff6536;
  border-radius: 50%;

  &:after {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: auto;
    margin-bottom: auto;
    border-top: 2px solid #ebffef;
    border-right: 2px solid #ebffef;
    content: "";
  }

  &:hover {
    opacity: .8;
  }
}

// ----------------------------------------------
.swiper-button-prev {
  &:after {
    right: 17px;
    transform: rotate(225deg);
  }
}

// ----------------------------------------------
.swiper-button-next {
  &:after {
    left: 17px;
    transform: rotate(45deg);
  }
}

// ----------------------------------------------
.swiper-fv {
  overflow: hidden;

  .swiper-slide {
    position: relative;

    .image {
      border-radius: 50px;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        border-radius: 0;
      }

      img {
        width: 100%;
      }
    }
  }

  .swiper-control {
    .swiper-pagination {
      position: absolute;
      right: -36px;
      bottom: 112px;
      transform: rotate(90deg);

      @include media-breakpoint-between(md, lg) {
        right: -65px;
      }

      .swiper-pagination-bullet {
        margin: 0 12px;
        width: 11px;
        height: 11px;
        opacity: 1;
        background: #fff;

        &.swiper-pagination-bullet-active {
          background: #ec6c00;
        }
      }
    }
  }
}
