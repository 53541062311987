@charset "UTF-8";

// ----------------------------------------------
.breadcrumb {
  padding-top: 1px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 17px;

  li {
    font-size: 12px;

    & > a {
      color: $gray;
      text-decoration: none;
    }

    &:not(:last-child) {
      &:after {
        content: "〉";
        display: inline-block;
        margin-right: 5px;
        margin-left: 14px;
      }
    }
  }
}
