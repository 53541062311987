@charset "UTF-8";

// ----------------------------------------------
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding-top: 45px;
  padding-bottom: 32px;
  z-index: 999;

  @include media-breakpoint-down(md) {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .logo {
    width: 198px;
    margin-left: 5px;

    @include media-breakpoint-down(md) {
      position: relative;
      z-index: 1;
      width: 183px;
      margin-top: -10px;
      margin-bottom: 0;
      margin-left: 0;
    }

    img {
      opacity: 1;
      visibility: visible;
      transition: all 350ms linear;

      &.clicked {
        position: absolute;
        opacity: 0;
        visibility: hidden;

        @include media-breakpoint-down(md) {
          top: 5px;
          left: 0;
        }
      }
    }

    &:hover {
      opacity: .5;
    }
  }

  .container-lg {
    @include media-breakpoint-down(md) {
      padding-right: 19px;
      padding-left: 17px;
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
  }

  .header-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 798px;

    @include media-breakpoint-between(md, xl) {
      justify-content: flex-end;
    }

    @include media-breakpoint-down(md) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-image: linear-gradient(-45deg, #fa6c01 0%, #fcbd0a 11.7378%, #ffffff 25.2752%, #92d1f4 57.1241%, #2ba2ee 81.5877%, #1d41d7 100%);
      background-size: 100% 200%;
      padding-top: 154px;
      padding-bottom: 154px;
      overflow-y: auto;
      // animation: background-linear 15s linear infinite forwards;
    }
  }

  .list-menu {
    display: flex;
    align-items: center;
    margin-right: 20px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-right: 0;
      margin-bottom: 54px;
    }

    li {
      &:not(:last-child) {
        margin-right: 35px;

        @include media-breakpoint-between(md, lg) {
          margin-right: 12px;
        }

        @include media-breakpoint-down(md) {
          margin-right: 0;
          margin-bottom: 53px;
        }
      }

      a {
        font-size: 17px;
        color: #000;
        text-decoration: none;
        transition: all 150ms linear;

        &:hover {
          color: $orange;
        }

        @include media-breakpoint-between(md, xl) {
          font-size: 14px;
        }

        @include media-breakpoint-between(md, lg) {
          font-size: 12px;
        }

        @include media-breakpoint-down(md) {
          color: #fff;
        }
      }
    }
  }

  .btn-container {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  .hamburger {
    display: block;
    position: relative;
    width: 28px;
    height: 28px;
    overflow: hidden;
    z-index: 1;

    @include media-breakpoint-up(md) {
      display: none;
    }

    @include media-breakpoint-down(md) {
      display: block;
    }

    span {
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background: $orange;
      transition: all 150ms linear;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 13px;
      }

      &:nth-child(3) {
        bottom: 0;
      }
    }

    &.hamburger-active {
      span {
        background: #fff;

        &:nth-child(1) {
          top: 13px;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          bottom: 13px;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.header-open {
    .logo {
      img {
        opacity: 0;
        visibility: hidden;

        &.clicked {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
