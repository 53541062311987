@charset "UTF-8";

// ----------------------------------------------
@mixin button-variant-hover-bright($background, $border, $color) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 25%);
  }

  &:hover {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 12%);
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: lighten($background, 17%);
      border-color: lighten($border, 25%);
    }
  }

  &:active,
  &.active {
    background-image: none;
  }

  .open > & {
    .dropdown-toggle {
      background-image: none;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  // .badge {
  //   color: $background;
  //   background-color: $color;
  // }
}

// ----------------------------------------------
@mixin button-variant-hover-inverse($background, $border, $color) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $background;
    background-color: $color;
    // border-color: darken($border, 25%);
  }

  &:hover {
    color: $background;
    background-color: $color;
    // border-color: darken($border, 12%);
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $background;
    background-color: $color;
    // border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $background;
      background-color: $color;
      // border-color: darken($border, 25%);
    }
  }

  &:active,
  &.active {
    background-image: none;
  }

  .open > & {
    .dropdown-toggle {
      background-image: none;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      // border-color: $border;
    }
  }

  // .badge {
  //   color: $background;
  //   background-color: $color;
  // }
}

// ----------------------------------------------
.btn-multiline {
  white-space: normal;
}

// ----------------------------------------------
.btn {
  @include transition(all, $hover-transition-speed, ease);
}

// ----------------------------------------------
.btn-bg-organe-arrow {
  display: inline-block;
  width: 225px;
  font-size: 17px;
  color: #fff;
  background: $orange url("../img/common/arrow-white.svg") no-repeat right 13px center/25px auto;
  border-radius: 8px;
  text-decoration: none;
  padding: 7px 39px 7px 15px;
  letter-spacing: .05rem;
  border: 1px solid $orange;
  transition: all 150ms linear;

  @include media-breakpoint-between(md, xl) {
    font-size: 14px;
    width: 180px;
    padding: 7px 30px 7px 10px;
    background: $orange url("../img/common/arrow-white.svg") no-repeat right 10px center/20px auto;
  }

  @include media-breakpoint-between(md, lg) {
    font-size: 13px;
    width: 170px;
  }

  &.btn-lg {
    width: 297px;
  }

  &.btn-xl {
    font-size: 23px;
    width: 307px;
    text-align: left;
    padding: 8px 39px 11px 21px;
    background: #ec6c00 url("../img/common/arrow-white.svg") no-repeat right 19px center/33px auto;

    &:hover {
      @include media-breakpoint-up(md) {
        background: #fff url("../img/common/arrow-orange.svg") no-repeat right 19px center/33px auto;
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      font-size: 21px;
      padding-bottom: 10px;
      background: #ec6c00 url("../img/common/arrow-white.svg") no-repeat right 9px center/33px auto;
    }
  }

  &.btn-xxl {
    width: 342px;
  }

  &:hover {
    @include media-breakpoint-up(md) {
      color: $orange;
      background: #fff url("../img/common/arrow-orange.svg") no-repeat right 13px center/25px auto;
    }
  }
}

// ----------------------------------------------
.common-btn {
  border: 1px solid #000;
  padding: 7px 9px 6px;
  font-size: 16px;
  background: #fff url("../img/icon/btn-arrow.svg") no-repeat right 7px center;
  background-size: 19px 14px;
  color: #000;
  display: inline-block;
  text-decoration: none;
  min-width: 205px;
  letter-spacing: .04rem;
  text-align: center;

  @include media-breakpoint-between(md, lg) {
    font-size: 13px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    padding: 7px 12px;
    background: #fff url("../img/icon/btn-arrow.svg") no-repeat right 14px center;
    background-size: 19px 14px;
  }

  &:hover {
    background: $dark-gray url("../img/common/arrow-white.svg") no-repeat right 7px center;
    background-size: 19px 14px;
    color: #fff;
  }

  &.btn-bg-transparent {
    background-color: transparent;
    letter-spacing: 0;

    &:hover {
      background-color: $dark-gray;
    }
  }

  &.btn-lg {
    min-width: 262px;

    @include media-breakpoint-down(md) {
      min-width: 100%;
    }
  }

  &.btn-xl {
    min-width: 385px;

    @include media-breakpoint-down(md) {
      min-width: 100%;
      padding-right: 65px;
    }
  }
}
