@import "variables";

// ----------------------------------------------
@import "colors";

// ----------------------------------------------
// Load & overwrite external libraries, plugins.
// @import "node_modules/sal.js/src/sal";
// @import "sal";

/*! purgecss start ignore */
@import "node_modules/swiper/swiper";
@import "node_modules/swiper/components/scrollbar/scrollbar";
@import "node_modules/swiper/components/pagination/pagination";
@import "node_modules/swiper/components/navigation/navigation";
@import "node_modules/swiper/components/effect-fade/effect-fade";
@import "swiper";

// ----------------------------------------------
@import "node_modules/aos/src/sass/aos";

// ----------------------------------------------
// Overwrite Twitter Bootstrap Core SCSS
@import "scaffolding";
@import "type";
@import "tables";
@import "forms";
@import "buttons";

// ----------------------------------------------
// Overwrite Twitter Bootstrap Components
@import "navs";
@import "breadcrumbs";
@import "pagination";
@import "media";
@import "list-group";
@import "panels";
@import "modal";
@import "utilities";
@import "responsive-utilities";
@import "badge";
@import "card";

// ----------------------------------------------
// Add Original Components
@import "title";
@import "frame";

// ----------------------------------------------
@import "section";
@import "components";
/*! purgecss end ignore */
