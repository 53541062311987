@charset "UTF-8";

// ----------------------------------------------
footer {
  background-color: $blue;
  padding: 48px 0 33px;

  @include media-breakpoint-down(md) {
    padding: 42px 0 17px;
    min-height: 846px;
  }

  .container {
    width: 100%;
    max-width: 890px;

    @include media-breakpoint-down(md) {
      padding-right: 23px;
      padding-left: 25px;
    }
  }

  .ft-container {
    margin-bottom: 7px;

    @include media-breakpoint-down(md) {
      display: block;
      margin-bottom: 26px;
    }
  }

  .ft-container-socical {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
      width: 100%;
      flex-direction: column;
    }

    .ft-logo {
      @include media-breakpoint-up(xl) {
        margin-left: 197px;
      }

      a {
        display: block;
        transition: $hover-transition-speed;

        @include media-breakpoint-down(md) {
          text-align: center;
        }

        &:hover {
          opacity: .7;
        }

        img {
          width: 316px;

          @include media-breakpoint-down(md) {
            width: 256px;
            margin-left: 20px;
          }
        }
      }
    }

    .list-soci {
      display: flex;
      flex-wrap: wrap;
      margin-left: 74px;
      margin-top: 19px;

      @include media-breakpoint-down(md) {
        justify-content: center;
        margin-bottom: 11px;
        margin-left: 0;
        margin-top: 33px;
      }

      li {
        width: 31px;

        @include media-breakpoint-down(md) {
          width: 35px;
        }

        & + li {
          margin-left: 16px;
        }

        a {
          transition: $hover-transition-speed;

          &:hover {
            opacity: .7;
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .ft-text {
    font-size: 12px;
    color: #fff;
    line-height: 1.7;

    &.ft-text-mb-1 {
      margin-bottom: 40px;

      @include media-breakpoint-down(md) {
        margin-bottom: 22px;
      }
    }

    &.ft-text-mb-2 {
      margin-bottom: 19px;
    }

    &.ft-text-mb-3 {
      margin-bottom: 23px;
    }

    a {
      color: #fff;
      text-decoration: none;

      @include media-breakpoint-up(md) {
        pointer-events: none;
      }

      &.mail {
        pointer-events: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .ft-container-nemu {
    margin-top: 39px;
    margin-bottom: 30px;

    @include media-breakpoint-between(md, lg) {
      padding-left: 20px;
    }

    @include media-breakpoint-down(md) {
      padding-left: 0;
      margin-top: 27px;
      margin-bottom: 25px;
    }

    .ft-menu {
      overflow: hidden;

      .ft-menu-item {
        display: inline-block;
        margin-left: 1.8%;
        margin-right: 1.8%;

        @include media-breakpoint-down(md) {
          display: block;
          min-height: inherit;
        }

        &:last-child {
          > a {
            @include media-breakpoint-down(md) {
              border-bottom: 1px solid #fff;
            }
          }
        }

        > a {
          font-size: 15px;
          color: #fff;
          display: block;
          text-decoration: none;

          @include media-breakpoint-between(md, lg) {
            font-size: 1.53vw;
          }

          @include media-breakpoint-down(md) {
            border-top: 1px solid #fff;
            padding: 9px 0 8px;
            margin-bottom: 0;
            background: url("../img/icon/menu-ft-arrow.svg") no-repeat right center;
            background-size: 8px 16px;
            transition: none;

            &.had-child {
              &.active {
                background: url("../img/icon/menu-ft-cross.svg") no-repeat right 10px center;
                background-size: 15px 15px;
                border-bottom: 1px solid #fff;
              }
            }
          }

          &:hover {
            @include media-breakpoint-up(md) {
              color: #3c3c3c;
            }
          }
        }
      }
    }

    .ft-text {
      margin-top: -2px;
      margin-left: 2px;
      text-indent: -5px;
    }
  }

  .ft-container-info {
    text-align: center;
    font-size: 12px;
    color: #fff;
    margin-bottom: 32px;
    line-height: 1.67;

    a {
      font-size: 12px;
      color: #fff;
      text-decoration: none;

      &.tel {
        @include media-breakpoint-up(md) {
          pointer-events: none;
        }
      }

      &.fax {
        pointer-events: none;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .dote {
      font-size: 7px;
      position: relative;
      top: -2px;

      @include media-breakpoint-down(md) {
        font-size: 9px;
      }
    }
  }

  .copyright {
    text-align: center;
    font-size: 13px;
    color: #fff;

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }
}

// ----------------------------------------------
.page-top {
  position: fixed;
  font-size: 14px;
  color: #000;
  right: 94px;
  bottom: 94px;
  // bottom: 524px;
  letter-spacing: .08rem;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  padding-right: 14px;
  padding-bottom: 3px;
  line-height: 1;
  text-decoration: none;
  transition: none;
  z-index: 10;

  &:after {
    content: "";
    position: absolute;
    top: -6px;
    right: -7px;
    width: 13px;
    height: 7px;
    background: url("../img/common/arrow-page-top.svg") no-repeat center center/100%;
    transition: all 250ms linear;
  }

  &:hover {
    &:after {
      @include media-breakpoint-up(md) {
        top: -9px;
      }
    }
  }

  &.fixed {
    position: absolute;
    bottom: 524px;
    transition: none;

    @include media-breakpoint-down(md) {
      bottom: 895px;
    }
  }

  @include media-breakpoint-down(md) {
    display: inline-block;
    position: fixed;
    right: auto;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    margin-bottom: 0;
  }
}
