@charset "UTF-8";

// ----------------------------------------------
.title-border-bottom {
  font-size: 20px;
  color: $gray;
  text-align: center;
  border-bottom: 1px solid $gray;
  padding-bottom: 1px;
  margin-bottom: 12px;

  &.size-32 {
    font-size: 32px;
    letter-spacing: .2rem;
    padding-bottom: 8px;
    margin-bottom: 12px;

    @include media-breakpoint-down(md) {
      font-size: 28px;
      margin-bottom: 10px;
    }
  }

  @include media-breakpoint-down(md) {
    font-size: 18px;
    padding-bottom: 2px;
    margin-bottom: 10px;
  }
}

.title-32 {
  font-size: 32px;
  text-align: center;
  letter-spacing: .2rem;
  margin-top: 24px;
  margin-bottom: 21px;

  span {
    font-size: 22px;
  }

  @include media-breakpoint-down(md) {
    font-size: 28px;
    letter-spacing: .1rem;
    margin-top: 16px;
    margin-bottom: 12px;
  }
}

.title-23 {
  font-size: 23px;
  text-align: left;
  font-weight: 500;

  @include media-breakpoint-down(md) {
    font-size: 20px;
  }
}

// ----------------------------------------------
